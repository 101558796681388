import React from 'react';
import { SeekActor } from './Seek/seekTypes';
import { FMDActor } from './FMDQueryComponent/fmdQueryComponentMachine';

// Define the shape of the context state
interface ApplicationContextState {
  fetch: () => Promise<any>;
  xstate: {
    fmdService: FMDActor | null;
    seekService: SeekActor | null;
  };
}

// Create the context with the initial state shape
const ApplicationContext = React.createContext<ApplicationContextState>({
  fetch: (): Promise<any> => {
    throw new Error('Fetch method not initialized.');
  },
  xstate: {
    fmdService: null,
    seekService: null,
  },
});

export default ApplicationContext;
