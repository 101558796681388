/* eslint-disable camelcase */
import { useContext } from 'react';
import {
  ActorRefFrom,
  assign,
  createMachine,
  MachineConfig,
  MachineOptions,
} from 'xstate';
import { useActor } from '@xstate/react';

import { EmptyObject, TissueNetwork } from '../shared/sharedTypes';
import { fetchRelevantNetworks } from '../../actions/NetworkActions';
import { getCommunityBodyTag } from '../community/community_util';
import { BASE_URI, TISSUE_DB } from '../../settings';
import ApplicationContext from '../ApplicationContext';
import { areEntrezListsEqual } from '../../core/util';

// TODO - a nice feature would be to store the promises generated by this machine
//  when community requests are made. This would allow us to avoid generating
//  the same request twice before the first request has a chance to finish.

type CommunityResult = any;
export type FMDActor = ActorRefFrom<typeof fmdQueryComponentMachine>;

// Utility hooks
export const useFMD = () => {
  const { xstate } = useContext(ApplicationContext);
  const { fmdService } = xstate;
  return useActor(fmdService as FMDActor);
};

const filterTissueIntegrations = (integrations: TissueNetwork[]) => {
  return integrations
    ? integrations.filter(
        integration =>
          integration?.context?.term?.database?.slug === TISSUE_DB ||
          integration.slug === 'global',
      )
    : [];
};

export interface FMDQueryComponentMachineContext {
  bodyTag: string | null;
  communityResult: CommunityResult | null;
  entrezList: string[];
  integrations: TissueNetwork[];
  relevantNetworks: TissueNetwork[];
  selectedNetwork: TissueNetwork | null;
  title: string;
}

type FetchCommunityNetworksDoneEvent = {
  type: 'done.invoke.fmdQueryComponent.networksManager.fetchingNetworks:invocation[0]';
  data: {
    integrations: TissueNetwork[];
    relevantNetworks: TissueNetwork[];
  };
};

type FetchCommunityDoneEvent = {
  type: 'done.invoke.fmdQueryComponent.communityQueryManager.fetchingCommunity:invocation[0]';
  data: {
    communityResult: CommunityResult;
  };
};

export type FMDQueryComponentMachineEvent =
  | { type: 'RESET' }
  | {
      type: 'FETCH_COMMUNITY';
      bodyTag?: string;
      entrezList?: string[];
      selectedNetwork?: TissueNetwork | null;
      title?: string;
    }
  | {
      type: 'UPDATE_ENTREZ_LIST';
      entrezList: string[];
    }
  | {
      type: 'UPDATE_SELECTED_NETWORK';
      selectedNetwork: TissueNetwork | null;
    }
  | {
      type: 'UPDATE_TITLE';
      title: string;
    }
  | FetchCommunityNetworksDoneEvent
  | FetchCommunityDoneEvent;

export interface FMDQueryComponentStateSchema {
  states: {
    networksManager: {
      states: {
        idle: EmptyObject;
        fetchingNetworks: EmptyObject;
        hasNetworks: EmptyObject;
      };
    };
    communityQueryManager: {
      states: {
        idle: EmptyObject;
        fetchingCommunity: EmptyObject;
        hasCommunity: EmptyObject;
      };
    };
  };
}

const defaultContext = {
  bodyTag: null,
  entrezList: [],
  communityResult: null,
  integrations: [],
  relevantNetworks: [],
  selectedNetwork: null,
  title: '',
};
const reset = {
  target: 'idle',
  actions: ['resetContext'],
};

export const fmdQueryComponentMachineConfig: MachineConfig<
  FMDQueryComponentMachineContext,
  FMDQueryComponentStateSchema,
  FMDQueryComponentMachineEvent
> = {
  schema: {
    context: {} as FMDQueryComponentMachineContext,
    events: {} as FMDQueryComponentMachineEvent,
  },
  id: 'fmdQueryComponent',
  type: 'parallel',
  context: defaultContext,
  states: {
    networksManager: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            RESET: reset,
            UPDATE_ENTREZ_LIST: [
              {
                cond: 'hasEntrezListChanged',
                actions: ['assignEntrezList', 'assignBodyTag'],
                target: 'fetchingNetworks',
              },
              {
                cond: 'needsNetworks',
                actions: ['assignEntrezList', 'assignBodyTag'],
                target: 'fetchingNetworks',
              },
            ],
          },
        },
        fetchingNetworks: {
          on: {
            RESET: reset,
            UPDATE_ENTREZ_LIST: [
              {
                cond: 'hasEntrezListChanged',
                actions: ['assignEntrezList', 'assignBodyTag'],
                target: 'fetchingNetworks',
              },
            ],
          },
          invoke: {
            src: 'fetchCommunityNetworks',
            onDone: {
              actions: [
                'assignRelevantNetworksAndIntegrations',
                'assignBodyTag',
              ],
              target: 'hasNetworks',
            },
            onError: {
              actions: 'sendSetError',
              target: 'idle',
            },
          },
        },
        hasNetworks: {
          on: {
            RESET: reset,
            UPDATE_ENTREZ_LIST: [
              {
                cond: 'hasEntrezListChanged',
                actions: ['assignEntrezList', 'assignBodyTag'],
                target: 'fetchingNetworks',
              },
            ],
          },
        },
      },
    },
    communityQueryManager: {
      initial: 'idle',
      states: {
        idle: {
          always: [
            {
              // If we have the same integration and entrez list as the communityResult query,
              //  we have the community data
              cond: (context: FMDQueryComponentMachineContext): boolean => {
                const {
                  entrezList,
                  selectedNetwork,
                  communityResult,
                  title,
                } = context;
                const query = communityResult?.query;
                const integrationSlug = query?.integration;
                const queryEntrezList = query?.entrez_list;
                const selectedNetworkSlug = selectedNetwork?.slug;
                const isSameTitle = title === query?.title;
                const isSameQuerySlug = integrationSlug === selectedNetworkSlug;
                const isSameEntrezList =
                  entrezList && queryEntrezList
                    ? areEntrezListsEqual(entrezList, queryEntrezList)
                    : false;
                return isSameQuerySlug && isSameEntrezList && isSameTitle;
              },
              target: 'hasCommunity',
            },
          ],
          on: {
            FETCH_COMMUNITY: [
              {
                cond: 'fetchHasEntrezAndTissueNetwork',
                actions: ['assignBodyTag', 'assignSelectedNetwork'],
                target: 'fetchingCommunity',
              },
              {
                target: 'fetchingCommunity',
                actions: ['assignBodyTag'],
              },
            ],
            UPDATE_SELECTED_NETWORK: {
              actions: ['assignSelectedNetwork', 'assignBodyTag'],
              target: 'idle',
            },
            UPDATE_TITLE: {
              actions: ['assignTitle', 'assignBodyTag'],
              target: 'idle',
            },
            UPDATE_ENTREZ_LIST: {
              target: 'idle',
            },
          },
        },
        fetchingCommunity: {
          on: {
            RESET: reset,
            FETCH_COMMUNITY: {
              target: 'fetchingCommunity',
              actions: ['assignBodyTag'],
            },
            UPDATE_ENTREZ_LIST: {
              target: 'idle',
            },
            UPDATE_SELECTED_NETWORK: {
              actions: ['assignSelectedNetwork', 'assignBodyTag'],
              target: 'idle',
            },
            UPDATE_TITLE: {
              actions: ['assignTitle', 'assignBodyTag'],
              target: 'idle',
            },
          },
          invoke: {
            src: 'fetchCommunity',
            onDone: {
              actions: [
                'assignCommunity',
                'assignSelectedNetwork',
                'assignTitle',
              ],
              target: 'hasCommunity',
            },
            onError: {
              actions: 'sendSetError',
              target: 'idle',
            },
          },
        },
        hasCommunity: {
          on: {
            RESET: reset,
            FETCH_COMMUNITY: {
              target: 'fetchingCommunity',
              actions: ['assignBodyTag'],
            },
            UPDATE_ENTREZ_LIST: {
              target: 'idle',
            },
            UPDATE_SELECTED_NETWORK: {
              actions: ['assignSelectedNetwork', 'assignBodyTag'],
              target: 'idle',
            },
            UPDATE_TITLE: {
              actions: ['assignTitle', 'assignBodyTag'],
              target: 'idle',
            },
          },
        },
      },
    },
  },
};

export const fmdQueryComponentMachineOptions: MachineOptions<
  FMDQueryComponentMachineContext,
  FMDQueryComponentMachineEvent
> = {
  activities: {},
  delays: {},
  guards: {
    fetchHasEntrezAndTissueNetwork: (_, event) => {
      if (event.type === 'FETCH_COMMUNITY') {
        return !!(event.entrezList && event.selectedNetwork);
      }
      return false;
    },
    hasEntrezListChanged: (context, event) => {
      if (event.type === 'UPDATE_ENTREZ_LIST') {
        return (
          JSON.stringify(context.entrezList.sort()) !==
          JSON.stringify(event.entrezList.sort())
        );
      }
      return false;
    },
    needsNetworks: (context, event) => {
      if (event.type === 'UPDATE_ENTREZ_LIST') {
        return (
          // We have entrez, but no integrations or networks
          context.integrations.length === 0 &&
          (context.entrezList.length > 0 || event.entrezList.length > 0)
        );
      }
      return false;
    },
  },
  services: {
    fetchCommunityNetworks: async (
      context: FMDQueryComponentMachineContext,
    ) => {
      const { entrezList } = context;
      const communityGenes = entrezList.map((entrez: string) => ({ entrez }));

      const relevantNetworksPromise = fetchRelevantNetworks(
        communityGenes,
      ).then(res => res.json());

      const integrationsPromise = fetch(`${BASE_URI}integrations/`).then(res =>
        res.json(),
      );

      const [relevantNetworks, integrations] = await Promise.all([
        relevantNetworksPromise,
        integrationsPromise,
      ]);

      return { integrations, relevantNetworks };
    },
    fetchCommunity: async (
      context: FMDQueryComponentMachineContext,
      event: FMDQueryComponentMachineEvent,
    ) => {
      const { bodyTag, entrezList, selectedNetwork, title } = context;
      let communityApiUrl = '';
      let body = '';
      if (event.type === 'FETCH_COMMUNITY' && event.bodyTag) {
        communityApiUrl = `${BASE_URI}integrations/community/?body_tag=${event.bodyTag}`;
      } else {
        communityApiUrl = `${BASE_URI}integrations/community/?integration=${
          selectedNetwork?.slug
        }&body_tag=${bodyTag}${title?.length ? `&title=${title}` : ''}`;
        body = `{ "entrez": [${entrezList.map(
          (entrez: string) => `"${entrez}"`,
        )}] }`;
      }
      const communityResult = await fetch(communityApiUrl, {
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json' },
      }).then(res => res.json());
      return { communityResult };
    },
  },
  actions: {
    assignBodyTag: assign(
      (
        context: FMDQueryComponentMachineContext,
        event: FMDQueryComponentMachineEvent,
      ) => {
        if (event.type === 'FETCH_COMMUNITY' && event.bodyTag)
          return { bodyTag: event.bodyTag };

        let tmpEntrezList = context.entrezList;
        let tmpSelectedNetwork: TissueNetwork | null =
          context.selectedNetwork || context.relevantNetworks?.[0];
        let tmpTitle = context.title;

        if (
          event.type === 'FETCH_COMMUNITY' &&
          event.entrezList &&
          event.selectedNetwork
        ) {
          tmpEntrezList = event.entrezList;
          tmpSelectedNetwork = event.selectedNetwork;
          if (event.title) tmpTitle = event.title;
        }

        if (event.type === 'UPDATE_ENTREZ_LIST') {
          const { entrezList } = event;
          tmpEntrezList = entrezList;
        }

        if (event.type === 'UPDATE_SELECTED_NETWORK') {
          const { selectedNetwork } = event;
          tmpSelectedNetwork = selectedNetwork;
        }

        if (event.type === 'UPDATE_TITLE') {
          const { title } = event;
          tmpTitle = title;
        }

        const bodyTag =
          tmpSelectedNetwork && tmpEntrezList.length > 0
            ? getCommunityBodyTag(
                tmpEntrezList.map(
                  (entrez: string) => ({ entrez }), // format for `getCommunityBodyTag`
                ),
                tmpSelectedNetwork?.slug,
                tmpTitle,
              )
            : null;
        return { bodyTag };
      },
    ),
    assignCommunity: assign(
      (
        context: FMDQueryComponentMachineContext,
        event: FMDQueryComponentMachineEvent,
      ) => {
        if (
          event.type ===
          'done.invoke.fmdQueryComponent.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          const { communityResult } = event.data;
          return { communityResult };
        }
        throw Error('Wrong event type name :: assignCommunity');
      },
    ),
    assignRelevantNetworksAndIntegrations: assign(
      (
        context: FMDQueryComponentMachineContext,
        event: FMDQueryComponentMachineEvent,
      ) => {
        if (
          event.type ===
          'done.invoke.fmdQueryComponent.networksManager.fetchingNetworks:invocation[0]'
        ) {
          const integration = context?.communityResult?.query?.integration;
          const { integrations, relevantNetworks } = event.data;
          const filteredIntegrations = filterTissueIntegrations(integrations);
          const filteredRelevantNetworks = filterTissueIntegrations(
            relevantNetworks,
          );
          return {
            ...context,
            integrations: filteredIntegrations,
            relevantNetworks: filteredRelevantNetworks,
            selectedNetwork:
              // Assign the current query slug if a result is present
              filteredIntegrations.find(item => item.slug === integration) ||
              // If selectedNetwork explicitly set by FETCH_COMMUNITY
              filteredRelevantNetworks.find(
                item => item.slug === integration,
              ) ||
              // Otherwise use top relevant network as default
              filteredRelevantNetworks[0],
          };
        }
        throw Error(
          'Wrong event type name :: assignRelevantNetworksAndIntegrations',
        );
      },
    ),
    assignEntrezList: assign(
      (
        context: FMDQueryComponentMachineContext,
        event: FMDQueryComponentMachineEvent,
      ) => {
        if (event.type === 'UPDATE_ENTREZ_LIST') {
          const { entrezList } = event;
          return {
            entrezList,
            integrations: [],
            relevantNetworks: [],
            selectedNetwork: null,
          };
        }

        if (event.type === 'FETCH_COMMUNITY') {
          const { entrezList } = event;
          return { entrezList };
        }
        return { entrezList: context.entrezList };
      },
    ),
    assignSelectedNetwork: assign(
      (
        context: FMDQueryComponentMachineContext,
        event: FMDQueryComponentMachineEvent,
      ) => {
        if (event.type === 'UPDATE_SELECTED_NETWORK') {
          const { selectedNetwork } = event;
          return { selectedNetwork };
        }
        if (event.type === 'FETCH_COMMUNITY' && event.selectedNetwork) {
          const { selectedNetwork } = event;
          return { selectedNetwork };
        }
        if (
          event.type ===
          'done.invoke.fmdQueryComponent.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          // In the case where the back / forward buttons fetch a new communityResult
          //  and we need to update selectedNetwork to match once the result is received
          const { communityResult } = event.data;
          const { integration } = communityResult.query;
          return {
            selectedNetwork: context.integrations.find(
              item => item.slug === integration,
            ),
          };
        }

        return { selectedNetwork: context.selectedNetwork };
      },
    ),
    assignTitle: assign(
      (
        context: FMDQueryComponentMachineContext,
        event: FMDQueryComponentMachineEvent,
      ) => {
        if (event.type === 'UPDATE_TITLE') {
          const { title } = event;
          return { title };
        }
        if (event.type === 'FETCH_COMMUNITY' && event.title) {
          const { title } = event;
          return { title };
        }
        if (
          event.type ===
          'done.invoke.fmdQueryComponent.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          // In the case where the back / forward buttons fetch a new communityResult
          //  and we need to update title to match once the result is received
          const { communityResult } = event.data;
          const communityResultTitle = communityResult?.query?.title;
          return { title: communityResultTitle || '' };
        }

        return { title: context.title };
      },
    ),
    resetContext: assign(() => {
      return defaultContext;
    }),
  },
};

const fmdQueryComponentMachine = createMachine(
  fmdQueryComponentMachineConfig,
  fmdQueryComponentMachineOptions,
);
export default fmdQueryComponentMachine;
